// src/Products.js
import React from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import Maxtron1 from "../images/Product Image M1 dropdown.png";
import Maxtron2 from "../images/Product ImageM2 dropdown.png";
import Maxtron3 from "../images/Product ImageM3 dropdown.png";
import Maxtron4 from "../images/Product Image M4 dropdown.png";

const ScooterData = () => {
  const navigate = useNavigate(); // Initialize navigate

  const products = [
    {
      id: 1,
      name: "Maxtron M1",
      image: Maxtron1,
      route: "/m1", // Add route
    },
    {
      id: 2,
      name: "Maxtron M2",
      image: Maxtron2,
      route: "/m2", // Add route
    },
    {
      id: 3,
      name: "Maxtron M3",
      image: Maxtron3,
      route: "/m3", // Add route
    },
    {
      id: 4,
      name: "Maxtron M4",
      image: Maxtron4,
      route: "/m4", // Add route
    },
  ];

  const handleImageClick = (route) => {
    navigate(route); // Navigate to the specified route
  };

  return (
    <div className="Scooter-products ">
      {products.map((product) => (
        <div key={product.id} className="Scooter-card">
          <img
            src={product.image}
            alt={product.name}
            onClick={() => handleImageClick(product.route)} // Add click handler
            style={{ cursor: "pointer" }} // Add cursor pointer for better UX
          />
          <p className="font-face-gm">{product.name}</p>
        </div>
      ))}
    </div>
  );
};

export default ScooterData;

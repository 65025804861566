import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import useNavigate and Link
import "./Navbar.css";
import Products from "../Hamburgur/Products";
import ScooterData from "../Hamburgur/ScooterData";
import Menu from "../Hamburgur/Menu";
import MobileMenu from "../MobileHamburgur/MobileMenu";
import Logo from "../images/Logos/MAXTRON_LOGO_FINAL_POPnMAXGREEN_(FORDARKBG).png";

const Navbar = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const [isScooterDropdownOpen, setScooterDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const toggleScooterDropdown = () =>
    setScooterDropdownOpen(!isScooterDropdownOpen);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuOpen]);

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <div className="logo" onClick={handleLogoClick}>
          <img src={Logo} alt="Maxtron Logo" />
        </div>

        {!isMobileView && (
          <div className="nav-item">
            <div
              className="dropdown"
              onMouseEnter={toggleScooterDropdown}
              onMouseLeave={toggleScooterDropdown}
            >
              <span className="nav-link font-face-gm">Scooters</span>
              {isScooterDropdownOpen && (
                <div className="dropdown-content animated-dropdown">
                  <div className="dropnew">
                    <ScooterData />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="navbar-right">
        {!isMobileView && (
          <>
            <Link to="/dealer" className="nav-link font-face-gm">
              Dealership
            </Link>
            <Link to="/testride" className="nav-link font-face-gm">
              Test Ride
            </Link>
            <Link to="/sbooking" className="nav-link order-now font-face-gm">
              Book Now
            </Link>
          </>
        )}

        <button className="nav-link" onClick={toggleMobileMenu}>
          <i className="fas fa-bars"></i>
        </button>
      </div>

      {isMobileView && isMobileMenuOpen && (
        <MobileMenu toggleMobileMenu={toggleMobileMenu} />
      )}

      {isMobileMenuOpen && !isMobileView && (
        <div className="mobile-menu">
          <div className="mContent">
            <Products toggleMobileMenu={toggleMobileMenu} />
            <Menu toggleMobileMenu={toggleMobileMenu} />
          </div>

          <div className="blue-backdrop"></div>

          <button onClick={toggleMobileMenu} className="mobile-menu-close">
            &times;
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import React from "react";
import "./Products.css";
import { useNavigate } from "react-router-dom";
import Maxtron1 from "../images/Product Image M1 dropdown.png";
import Maxtron2 from "../images/Product ImageM2 dropdown.png";
import Maxtron3 from "../images/Product ImageM3 dropdown.png";
import Maxtron4 from "../images/Product Image M4 dropdown.png";

const Products = ({ toggleMobileMenu }) => {
  const navigate = useNavigate();

  const products = [
    {
      id: 1,
      name: "Maxtron M1",
      image: Maxtron1,
      route: "/m1",
    },
    {
      id: 2,
      name: "Maxtron M2",
      image: Maxtron2,
      route: "/m2",
    },
    {
      id: 3,
      name: "Maxtron M3",
      image: Maxtron3,
      route: "/m3",
    },
    {
      id: 4,
      name: "Maxtron M4",
      image: Maxtron4,
      route: "/m4",
    },
  ];

  const handleImageClick = (route) => {
    toggleMobileMenu(); // Close the mobile menu
    navigate(route); // Navigate to the specified route
  };

  return (
    <div className="products">
      {products.map((product) => (
        <div
          key={product.id}
          className="product-card"
          onClick={() => handleImageClick(product.route)}
        >
          <img src={product.image} alt={product.name} />
          <p>{product.name}</p>
        </div>
      ))}
    </div>
  );
};

export default Products;

import React, { useState, useEffect } from "react"; // Added useEffect here
import "./Dealership.css";
import {
  FaBatteryFull,
  FaRocket,
  FaShieldAlt,
  FaMobileAlt,
  FaStream,
  FaDollarSign,
} from "react-icons/fa";
import WhatsAppPopup from "../DelershipPoUp/WhatsAppPopupD";

const DealerProgramPage = () => {
  const [showForm, setShowForm] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleFormToggle = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const usps = [
    {
      id: 1,
      icon: <FaBatteryFull />,
      title: "Revolutionary Battery Tech",
      description: `Experience the future of riding with Maxtron's advanced battery technology! Providing extra miles and fast charging, taking the lead in extended riding sessions and ensuring minimum time between charging cycles. `,
    },

    {
      id: 2,
      icon: <FaMobileAlt />,
      title: "Advanced Features for Safety and Convenience",
      description: ` Maxtron EV Scooters are installed with bright LED lights for enhanced visibility, a reverse gear for easy manoeuvring, and a remote alarm system for added security, making your ride safer and more convenient every day.`,
    },
    {
      id: 3,
      icon: <FaRocket />,
      title: "Premium Performance and Speed",
      description: `Maxtron EV two-wheelers provide high performance and speed, giving fast acceleration and top speeds with higher performance than their traditional combustion engine counterparts, offering strong power while contributing to a cleaner environment.`,
    },
    {
      id: 4,
      icon: <FaShieldAlt />,
      title: "Robust Safety Features",
      description: `Maxtron ensures the safety of riders with a completely advanced set of safety features in the form of collision prevention systems, anti-lock braking systems, and stability control, ensuring comfort and confidence for the riders on the road.`,
    },
    {
      id: 5,
      icon: <FaStream />,
      title: "Sleek and Innovative Designs",
      description: `Maxtron electric vehicles feature sleek and innovative designs compared to traditional scooters and motorcycles, offering greater functionality and making a bold impression on the road.`,
    },
    {
      id: 5,
      icon: <FaDollarSign />,
      title: "Cost Effective",
      description: `Maxtron EV scooters save fuel, substantially reduce maintenance costs, and have a lower total cost of ownership than Internal Combustion vehicle models. They are cheaper and more eco-friendly since they run purely on electricity.`,
    },
  ];

  return (
    <div className="dealer-program-page">
      {/* Show the popup conditionally */}
      {/* {showPopup && <WhatsAppPopup closePopup={closePopup} />} */}

      {/* Rest of your DealerProgramPage content */}
      {/* Unique Selling Proposition Section */}
      <section className="usp-section">
        <h2 className="usp-title">Unique Selling Proposition</h2>
        <div className="usp-cards">
          {usps.map((usp) => (
            <div key={usp.id} className="usp-card">
              <div className="usp-card-icon">{usp.icon}</div>
              <h3 className="usp-card-title">{usp.title}</h3>
              <p className="usp-card-description">{usp.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Why Partner with Maxtron Section */}
      <section className="why-partner-section">
        <h2 className="section-title">Why Partner with Maxtron</h2>
        <div className="partner-benefits">
          <div>
            <strong>Innovative Products</strong>
            <p>
              Maxtron represents next-generation EV scooters with brushless hub
              motors, lithium/lead-acid batteries, and all the smart features
              that an unknown riding adventure has yet to experience so far.
            </p>
          </div>
          <div>
            <strong>Diverse Range</strong>
            <p>
              Maxtron offers a range that best fits a customer's demands with
              power-packed mechanisms and appealing designs for those who value
              reliability in association with quality.
            </p>
          </div>
          <div>
            <strong>Proven Quality</strong>
            <p>
              Maxtron EV Scooters are known for their dependable performance and
              top-notch quality. Each Maxtron vehicle undergoes numerous
              rigorous testing for safety and customer satisfaction.
            </p>
          </div>
          <div>
            <strong>Dealer Support</strong>
            <p>
              Benefit from comprehensive support, including marketing
              assistance, product training, and responsive customer service. We
              are committed to your success!
            </p>
          </div>
          <div>
            <strong>Sustainability Commitment</strong>
            <p>
              Maxtron is committed to sustainable practices that help to protect
              the environment. Choosing us means you're getting top-quality
              products and supporting a brand that values environmental
              responsibility.
            </p>
          </div>
          <div>
            <strong>Competitive Pricing</strong>
            <p>
              Our Pricing Strategy is competitive enough for our partners to
              remain on top without sacrificing the quality. Maxtron offers
              top-notch products without compromising on value, ensuring that
              customers are satisfied without breaking their budget.
            </p>
          </div>
        </div>
      </section>

      {/* How to Become a Dealer Section */}
      <section className="how-to-become-section">
        <h2 className="section-title">How to Become a Maxtron Dealer</h2>
        <div className="steps">
          <div>
            <strong>Reach Out</strong>
            <p>
              To become a Maxtron dealer, Show your interest by connecting with
              us for more detailed information.
            </p>
          </div>
          <div>
            <strong>Qualifications</strong>
            <p>
              Maxtron dealers are expected to uphold high standards of quality
              and excellence to promote electric mobility and sustainable
              transportation. Passionate partners for an electric solution can
              shape the future of mobility.
            </p>
          </div>
          <div>
            <strong>Agreement</strong>
            <p>
              We believe in providing a clear partnership agreement outlining
              responsibilities, expectations, and benefits. This transparency
              builds trust, paving the way for mutual success and a strong
              relationship.
            </p>
          </div>
          <div>
            <strong>Product Delivery</strong>
            <p>
              Maxtron's top-tier electric scooters are delivered very soon after
              application approval to guarantee immediate delivery to meet the
              demand of conscious consumers who care about nature and to amaze
              their customers with their innovative designs.
            </p>
          </div>
          <div>
            <strong>Networking Opportunities</strong>
            <p>
              Maxtron dealers are allowed to join the electric mobility
              community through unique networking events and forums where
              connections, collaborations, and innovation in business growth and
              sales strategies are promoted.
            </p>
          </div>
          <div>
            <strong>Feedback Loop</strong>
            <p>
              Maxtron values dealers' contribution to growth and development,
              working collaboratively to refine offerings, improve features, and
              lead ahead of industry trends for its customers' exceptional
              electric mobility experience.
            </p>
          </div>
        </div>
      </section>

      {/* Maxtron Dealership Benefits Section */}
      <section className="dealership-benefits-section">
        <h2 className="section-title">Maxtron Dealership Benefits</h2>
        <div className="benefits">
          <div>
            <strong>Lucrative Margins</strong>
            <p>Enjoy competitive margins and growing revenue potential.</p>
          </div>
          <div>
            <strong>Exclusive Access</strong>
            <p>Stay ahead by offering the latest Maxtron models.</p>
          </div>
          <div>
            <strong>Brand Recognition</strong>
            <p>Associate your business with Maxtron’s leading brand in EVs.</p>
          </div>
        </div>
      </section>

      {/* Maxtron Dealer Program Section */}
      <section className="dealer-program-section">
        <h2 className="section-title">
          Maxtron Dealer Program: Seize the Electric Revolution!
        </h2>
        <p style={{ textAlign: "center" }}>
          Expand your business with Maxtron, a pioneer in electric mobility.
          Join us today to explore growth opportunities in the dynamic electric
          vehicle industry.
        </p>

        {/* Add Button for Opening Dealership Form */}
        <button className="btn-open-form">
          Join the Maxtron Dealer Program
        </button>

        {/* Conditionally Render the Form */}

        <div className="dealership-form">
          <h3>Dealership Application Form</h3>
          <form>
            <label>
              Full Name:
              <input type="text" placeholder="Enter your full name" />
            </label>
            <label>
              Email:
              <input type="email" placeholder="Enter your email" />
            </label>
            <label>
              Phone Number:
              <input type="tel" placeholder="Enter your phone number" />
            </label>
            <label>
              Business Name:
              <input type="text" placeholder="Enter your business name" />
            </label>
            <label>
              Location:
              <input type="text" placeholder="Enter your business location" />
            </label>
            <button type="submit">Submit Application</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default DealerProgramPage;

import React from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import "./Footer.css"; // Separate CSS file for styling
import Logo from "../images/Logos/MAXTRON_LOGO_FINAL_POPnMAXGREEN_(FORDARKBG).png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-Footer">
        <div className="footer-columns">
          <div className="footer-logo">
            <img src={Logo} alt="Maxtron Logo" />
          </div>
          <div className="footer-links">
            <h3>Products</h3>
            <ul>
              <li>
                <Link to="/m1">Maxtron M1</Link>
              </li>
              <li>
                <Link to="/m2">Maxtron M2</Link>
              </li>
              <li>
                <Link to="/m3">Maxtron M3</Link>
              </li>
              <li>
                <Link to="/m4">Maxtron M4</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h3>Tech & Design</h3>
            <ul>
              <li>
                <Link to="/software">Software</Link>
              </li>
              <li>
                <Link to="/manufacturing">Manufacturing</Link>
              </li>
              <li>
                <Link to="/design">Design</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h3>Ownership</h3>
            <ul>
              <li>
                <Link to="/exp-center">Exp Center</Link>
              </li>
              <li>
                <Link to="/accessories">Accessories</Link>
              </li>
              <li>
                <Link to="/community">Community</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h3>Company</h3>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
              <li>
                <Link to="/newsroom">Newsroom</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-social">
          <a
            href="https://www.instagram.com/maxtronev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.facebook.com/share/1Dg4yYE3yf/?mibextid=qi2Omg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/maxtron-ev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>

        <div className="footer-bottom">
          <p>© 2024 Maxtron. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

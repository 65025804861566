import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import ContectUsimg from "../images/Contact Us Image copy.jpg";
import "./ContectUs.css";

export default function ContactUs() {
  const [formType, setFormType] = useState("sales");
  const formRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    // Sets the image height to match the form height
    if (formRef.current && imgRef.current) {
      imgRef.current.style.height = `${formRef.current.offsetHeight}px`;
    }
  }, [formType]); // Runs every time the formType changes to update image height accordingly

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted");
  };

  return (
    <div className="contectUsr">
      <div style={{ maxWidth: "1200px", margin: "auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth < 600 ? "column" : "row", // Responsive layout
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div className="mhight" style={{ width: "100%", maxWidth: "600px" }}>
            <img
              ref={imgRef}
              src={ContectUsimg}
              alt="Maxtron E-Scooter"
              style={{
                width: "100%",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          </div>
          <Card ref={formRef} style={{ width: "100%", maxWidth: "600px" }}>
            <CardHeader className="font-face-gm2" title="Maxtron E-Scooters" />
            <CardContent>
              <Typography variant="subtitle1" align="center" gutterBottom>
                Become a Dealership | Buy a Maxtron E-Scooter | Support
              </Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="form-type-label">Select Form Type</InputLabel>
                  <Select
                    labelId="form-type-label"
                    value={formType}
                    label="Select Form Type"
                    onChange={(event) => setFormType(event.target.value)}
                  >
                    <MenuItem value="sales">Sales</MenuItem>
                    <MenuItem value="service">Service</MenuItem>
                    <MenuItem value="support">Support</MenuItem>
                    <MenuItem value="grievance">Grievance</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  label="Name"
                  placeholder="Enter your name"
                  required
                />
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  placeholder="Enter your email"
                  required
                />
                <TextField
                  fullWidth
                  label="Phone Number"
                  type="tel"
                  placeholder="Enter your phone number"
                  required
                />

                {formType === "sales" && (
                  <FormControl fullWidth>
                    <InputLabel id="interest-label">Interest</InputLabel>
                    <Select
                      labelId="interest-label"
                      defaultValue="dealership"
                      label="Interest"
                    >
                      <MenuItem value="dealership">
                        Become a Dealership
                      </MenuItem>
                      <MenuItem value="purchase">Purchase E-Scooter</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {formType === "service" && (
                  <TextField
                    fullWidth
                    label="E-Scooter Model"
                    placeholder="Enter your E-Scooter model"
                  />
                )}

                {(formType === "support" || formType === "grievance") && (
                  <TextField
                    fullWidth
                    label="Message"
                    placeholder="Enter your message"
                    multiline
                    rows={4}
                  />
                )}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
